import React, { useState } from 'react';
import './Question.scss'
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const Question = (props) =>{

    const [isOpen, setIsOpen] = useState(false)

    let classes = isOpen? ["content open"]: ["content closed"];
    let icon = (isOpen? <FontAwesomeIcon className="item__edit-icon btn" icon={faMinus} />:
                <FontAwesomeIcon className="item__delete-icon btn" icon={faPlus} />);
    return(
        <div className="question">
            <button type="button" className="collapsible" onClick={()=>setIsOpen(!isOpen)}>
                <p className="text">{props.question}</p>
                {icon}                                  
            </button>
            <div className={classes}>
                {/* <div> */}
                <span className="answer" dangerouslySetInnerHTML={{__html: props.answer}}>
                </span>
                {/* </div> */}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Question);