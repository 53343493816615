import React from 'react';
import classes from './Toolbar.scss';
import NavigationItems from '../NavigationItems/NavigationItems';
import Logo from '../../Logo/Logo';

const toolbar = (props) => (
    <header>
        <div className="header">
            <Logo />
            <nav>
                <NavigationItems/>
            </nav>
        </div>
    </header>
);

export default toolbar;