import * as firestoreService from '../config/fsConfig';
import { v4 as uuidv4 } from 'uuid';

export const ADD_REGISTRATION = 'ADD_REGISTRATION';
export const INIT_REGISTRATIONS = 'INIT_REGISTRATIONS';
export const DELETE_REGISTRATION = 'DELETE_REGISTRATION';
export const SELECT_REGISTRATION = 'SELECT_REGISTRATION';
export const REGISTRATION_STATUS_SUCCESS = 'REGISTRATION_STATUS_SUCCESS';
export const REGISTRATION_STATUS_FAILED = 'REGISTRATION_STATUS_FAILED';
export const REGISTRATION_STATUS_RESET = 'REGISTRATION_STATUS_RESET';
export const LOADING_STATUS = 'LOADING_STATUS'

export const setLoadingStatus = (status) => {
    return {
        type: LOADING_STATUS,
        loading: status
    }
}



export const initRegistrationsHelper = (registrations) => {
    return {
        type: INIT_REGISTRATIONS,
        registrations: registrations
    }
}

export const selectRegistrationHelper = (registrationId) => {
    return {
        type: SELECT_REGISTRATION,
        registrationId: registrationId
    }
}
export const saveRegistration = (registration) => {
    return {
        type: ADD_REGISTRATION,
        registration: registration
    }
};
export const removeRegistration = (registrationId) => {
    return {
        type: DELETE_REGISTRATION,
        registrationId: registrationId
    }
}
export const updateRegistrationStatusToSuccess = () => {
    return {
        type: REGISTRATION_STATUS_SUCCESS
    }
}
export const updateRegistrationStatusToFailed = () => {
    return {
        type: REGISTRATION_STATUS_FAILED
    }
}

function uploadRegistrationImage(imageFile, folder) {
    return new Promise((resolve, reject) => {
        firestoreService.uploadRegistrationImage(imageFile, folder)
        .on('state_changed', 
        (snapShot) => {
        }, (err) => {
          console.log(err)
        }
        , ()=> {
            resolve(imageFile.name)
        })
    });
}
const getImageUrlPromise = (imagename, folderName) => {return new Promise((resolve, reject)=> {
    firestoreService.getImageUrl(imagename, folderName)
    .then(url => {
        resolve(url)
    })
})};
const getRegistrationWithImageURL = (registration, url) => {
    return new Promise((resolve, reject)=> {
        const newRegistration = { 
            registration: {
                ...registration.registration,
                emiratesIDBack: url
            }          
        }
        resolve(newRegistration)
    })
}
const getRegistrationWithEmiratesFront = (registration, url) => {
    return new Promise((resolve, reject)=> {
        const newRegistration = { 
            registration: {
                ...registration.registration,
                emiratesIDFront: url
            }          
        }
        resolve(newRegistration)
    })
}
const getRegistrationWithStudentFront = (registration, url) => {
    return new Promise((resolve, reject)=> {
        const newRegistration = { 
            registration: {
                ...registration.registration,
                studentIDFront: url
            }          
        }
        resolve(newRegistration)
    })
}

const getHashName = () =>{
    return uuidv4(); 
}

export const setStatus = () =>{
    return {
        type: REGISTRATION_STATUS_RESET
    }
}
export const addRegistration = (registration, registrationImages) => {
        return (dispatch) => {

            dispatch(setLoadingStatus(true))


            var emiratesIDBackData = registrationImages['emiratesIDBack'].slice(0, registrationImages['emiratesIDBack'].size, 'image/png'); 
            const newEBID = new File([emiratesIDBackData], getHashName(), {type: 'image/png'});
            var emiratesIDFrontData = registrationImages['emiratesIDFront'].slice(0, registrationImages['emiratesIDFront'].size, 'image/png'); 
            const newEFID = new File([emiratesIDFrontData], getHashName(), {type: 'image/png'});
            var studentIDFrontData = registrationImages['studentIDFront'].slice(0, registrationImages['studentIDFront'].size, 'image/png'); 
            const newSFID = new File([studentIDFrontData], getHashName(), {type: 'image/png'});
            

            let newRegistration = registration;
            const emiratesIDBackFolder  = 'emiratesIDBack';
            const emiratesIDFrontFolder = 'emiratesIDFront';
            const studentIDFrontFolder = 'studentIDFront';
   
            uploadRegistrationImage(newEBID, emiratesIDBackFolder)
                // .then(name=> {
                //     console.log(name)
                //     return getImageUrlPromise(name)
                // })
                .then((name) => {
                    return getRegistrationWithImageURL(newRegistration, name )
                })
                .then(reg => {
                    newRegistration = reg;
                    return uploadRegistrationImage(newEFID, emiratesIDFrontFolder)
                })
                .then((name) => {
                    return getRegistrationWithEmiratesFront(newRegistration, name)
                })
                .then(reg => {
                    newRegistration = reg;
                    return uploadRegistrationImage(newSFID, studentIDFrontFolder)
                })
                .then((name) => {
                    return getRegistrationWithStudentFront(newRegistration, name )
                })
                .then((reg) => {
                    firestoreService.createRegistration(reg, reg.registration.emiratesId)
                    .then((docRef)=>{
                        dispatch(updateRegistrationStatusToSuccess())
                        dispatch(setLoadingStatus(false))
                    })
                    .catch(e=>{
                        throw e
                    })
                })
                .catch(error => {
                    dispatch(updateRegistrationStatusToFailed())
                    dispatch(setLoadingStatus(false))
                })
    
        }




            // firestoreService.createRegistration(registration)
            // .then(function(docRef) {
            //     console.log(docRef)
            //     const updatedRegistration = {
            //         ...registration,
            //         id: docRef.id
            //     }
            //     dispatch(saveRegistration(updatedRegistration))
            // })
            // .catch(function(error) {
            //     console.error("Error writing document: ", error);
            // });
        
}
export const deleteRegistration = (registrationId) => {
    console.log(registrationId)
    return (dispatch) => {
        dispatch(removeRegistration(registrationId))
    }
}
export const selectRegistration = (registrationId) => {
    return (dispatch) => {
        dispatch(selectRegistrationHelper(registrationId));
    }
}

export const initRegistrations = () => {
    return (dispatch) => {
        let registrationsArray = [];
        firestoreService.getRegistrationList()
        .then(updatedGroceryList => {
            registrationsArray = updatedGroceryList;
            dispatch(initRegistrationsHelper(registrationsArray))
        })
        .catch((error) => console.log(error));
        // dispatch(initRegistrationsHelper());
    }
}

export const onRegistrationStarted = () => {
    console.log("started")
    return (dispatch) => {
        firestoreService.logRegistrationStart();
    }
}
