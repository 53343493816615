import React from 'react';
import dellLogo from '../../assets/delllogo.svg';
import emircomLogo from '../../assets/emircom.svg';
import classes from './Logo.scss';

const logo = (props) => (
    <div className="logo">
        <img src={emircomLogo} alt="logo" />
        <span className="logo-divider">|</span>
        <img src={dellLogo} alt="logo" />
    </div>
);

export default logo;