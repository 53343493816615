import React, { Component, Fragment, useRef } from "react";
import PropTypes, { element } from "prop-types";
import * as firestoreService from "../../../config/fsConfig";
import { connect } from "react-redux";
import classes from "./NewRegistration.scss";
import Input from "../../UI/Input/Input";

import * as actionTypes from "../../../store/actions";
// import {addRegistration} from '../../../store/actions';

class NewRegistration extends Component {
  state = {
    // registrations: null
    registration: {
      name: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Full Name",
        },
        value: "",
        label: "Name",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "you@awesome.com",
        },
        value: "",
        label: "Email",
        validation: {
          required: true,
          regex: new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        },
        valid: false,
        touched: false,
      },
      emiratesId: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "XXX-XXXX-XXXXXXX-X",
        },
        value: "",
        label: "Student's Emirates ID",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      institution: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        label: "School / Institution",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      grade: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Ex: Grade 9",
        },
        value: "",
        label: "Grade/Class",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      nationality: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        label: "Nationality",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      phone: {
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "+971-",
        },
        value: "",
        label: "Contact Number",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      studentId: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        label: "Student ID",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      dateOfBirth: {
        elementType: "date",
        elementConfig: {
          type: "date",
          placeholder: "XX-XX-XXXX",
        },
        value: "",
        label: "Date Of Birth",
        validation: {
          required: true,
          beforeDate: new Date(),
        },
        valid: false,
        touched: false,
      },
      emiratesIDFront: {
        elementType: "file",
        elementConfig: {
          type: "file",
        },
        value: "",
        label: "Student's Emirates ID Front",
        validation: {
          requiredFile: true,
        },
        id: "emiratesIDFront",
        name: "emiratesIDFront",
        valid: false,
        touched: false,
      },
      emiratesIDBack: {
        elementType: "file",
        elementConfig: {
          type: "file",
        },
        value: "",
        label: "Student's Emirates ID Back",
        validation: {
          requiredFile: true,
        },
        id: "emiratesIDBack",
        name: "emiratesIDBack",
        valid: false,
        touched: false,
      },
      studentIDFront: {
        elementType: "file",
        elementConfig: {
          type: "file",
        },
        value: "",
        label: "Student ID Front",
        validation: {
          requiredFile: true,
        },
        id: "emiratesIDFront",
        name: "emiratesIDFront",
        valid: false,
        touched: false,
      },
    },
  };

  componentWillMount() {
    this.props.onRegistrationStarted();
  }
  componentWillUnmount() {}
  checkValidity(value, rules) {
    let isValid = false;
    if (rules.required) {
      isValid = value.trim() !== "";
    }
    if (rules.requiredFile) {
      isValid = true;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.beforeDate) {
      isValid = new Date(value) < rules.beforeDate && isValid;
    }
    if (rules.regex) {
      isValid = rules.regex.test(value) && isValid;
    }
    return isValid;
  }

  registrationDataHandler = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const formData = {};
    for (let formElementIdentifier in this.state.registration) {
      formData[formElementIdentifier] = this.state.registration[
        formElementIdentifier
      ].value;
    }

    const uploadPayload = {
      emiratesIDBack: formData.emiratesIDBack,
      emiratesIDFront: formData.emiratesIDFront,
      studentIDFront: formData.studentIDFront,
    };
    const registrationPayload = {
      registration: formData,
    };
    const registrationPayloadWithoutFiles = {
      registration: {
        ...registrationPayload.registration,
        emiratesIDFront: "",
        emiratesIDBack: "",
        studentIDFront: "",
      },
    };
    // console.log(registrationPayloadWithoutFiles)
    this.props.onRegistrationAdded(
      registrationPayloadWithoutFiles,
      uploadPayload
    );

    // const data = {
    //     name: this.state.name
    // }
    // firestoreService.createRegistration(data.name);
    // Axios.post("/registrations/", data)
    //   .then(Response => {
    //
    //   });
  };

  handleRegistrationAddition = () => {
    this.props.onRegistrationAdded(this.state.registration);
    this.setState({
      registration: {
        name: "",
      },
    });
  };

  handleChange = (e, id) => {
    const updatedRegistrationForm = {
      ...this.state.registration,
    };
    const updatedFormElement = { ...updatedRegistrationForm[id] };
    if (updatedRegistrationForm[id].elementConfig.type === "file") {
      updatedFormElement.value = e.target.files[0];
      updatedFormElement.valid = this.checkValidity(
        updatedFormElement.value,
        updatedFormElement.validation
      );
    } else {
      updatedFormElement.value = e.target.value;
      updatedFormElement.valid = this.checkValidity(
        updatedFormElement.value,
        updatedFormElement.validation
      );
    }

    updatedRegistrationForm[id] = updatedFormElement;
    let formIsValid = true;
    updatedFormElement.touched = true;
    for (let id in updatedRegistrationForm) {
      formIsValid = updatedRegistrationForm[id].valid && formIsValid;
    }
    this.setState({
      registration: updatedRegistrationForm,
      formIsValid: formIsValid,
    });
  };

  render() {
    const formElementsArray = [];
    for (let key in this.state.registration) {
      formElementsArray.push({
        id: key,
        config: this.state.registration[key],
      });
    }
    return (
      <Fragment>
        {/* <h1>{this.props.cats}</h1> */}
        <div className="newRegistration">
          <div className="wrapper">
            <div>
              <h2>Register</h2>
              <span className="tagline">
                Fill in your details to get the coupon code
              </span>
              <div className="underline"></div>
            </div>
            <p className="tagline">
              Thank you very much for your interest in our product. We regret to
              inform you that this item is currently out of stock. We expect to
              receive a shipment by the end of the month
            </p>
            {/* <form onSubmit={this.registrationDataHandler}>
                  <div className="form">
                  {
                    formElementsArray.map(element => (
                      <div key={element.id} className="form__element">
                      <Input 
                          elementType={element.config.elementType} 
                          elementConfig={element.config.elementConfig}
                          id = {element.id}
                          name = {element.config.name} 
                          label={element.config.label}                           
                          value={element.config.value}
                          invalid={!element.config.valid}
                          touched={element.config.touched}
                          changed={(event) => this.handleChange(event, element.id)}
                      />                        
                      </div>
                    ))
                  }
                  </div>                


                <div className="form">
                  <button className="button" disabled={!this.state.formIsValid} type="submit">{this.props.loading?'Registering..':'Register'}</button>
                </div>
                </form>  */}
          </div>
        </div>
      </Fragment>
      //   <Fragment>
      //     <div>
      //           <h1>Add new Item</h1>
      //           <label>Name</label>
      //           <input name="name" type="text" value={this.state.name} onChange={this.handleChange}></input>
      //           <input name="description" type="text" value={this.state.description} onChange={this.handleChange}></input>
      //           <button onClick={this.handleItemAddition}>Add Item</button>
      //      </div>
      // </Fragment>
      //Fragment is same as Aux
    );
  }
}
NewRegistration.propTypes = {
  click: PropTypes.func,
  name: PropTypes.string,
};

const mapStateToProps = (state) => {
  console.log(state.reducer.loading);
  return {
    status: state.status,
    error: state.error,
    loading: state.reducer.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRegistrationStarted: () => dispatch(actionTypes.onRegistrationStarted()),
    onRegistrationAdded: (registration, registrationImages) =>
      dispatch(actionTypes.addRegistration(registration, registrationImages)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewRegistration);
