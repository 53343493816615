import React, {useRef, useEffect } from 'react';
import { TimelineMax } from "gsap";
import './JumboLeft.scss'
import laptopsmall from '../../assets/laptopsmall.png';
import laptopcircle330 from '../../assets/laptopcircle330.png'
import laptopcircle680 from '../../assets/laptopcircle680.png'
import laptopcircle937 from '../../assets/laptopcircle937.png'
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';

const JumboLeft = () =>{

  const headingRef = useRef(null);
  const taglineRef = useRef(null);
  const realPriceRef = useRef(null);
  const offerPriceRef = useRef(null);
  const priceTaglineRef = useRef(null);
  const lapRef = useRef(null);

  let tl  = new TimelineMax()
  useEffect(() => {       
    // gsap.from(headingRef.current, {
    //     autoAlpha: 0,
    //     ease: 'none',
    //     delay: 1
    // });
    tl.from(lapRef.current, 0.3, {
      autoAlpha: 0,
      ease: 'none',
      delay: 0.6
  })
    .from(headingRef.current, 0.3, {
      autoAlpha: 0,
      ease: 'none',
      delay: 0.1
  })
    .from(taglineRef.current, 0.6, {
      autoAlpha: 0,
      ease: 'none',
      delay: 0.1
    })
    .from(priceTaglineRef.current, 0.6, {
      autoAlpha: 0,
      ease: 'none',
      delay: 0.1
    })
    .from(realPriceRef.current, 0.6, {
      autoAlpha: 0,
      x: '-1rem',
      delay: 0.1
    })
    .from(offerPriceRef.current, 0.3, {
      autoAlpha: 0,
      x: '-2rem',
      ease: 'none',
      delay: 0.05
    })
}, []);
    return(
        <div className="left" id="left">
          <div className="details">
            <h1 ref={headingRef}>Heading back to school?</h1>
            <h4 ref={taglineRef}>Power Through Your School Day With Dell 3310</h4>
            <p ref={priceTaglineRef}>At a special price of</p>
            <div className="price">
              <h3 ref={realPriceRef} className="price--real">AED 3050</h3>
              <h3 ref={offerPriceRef} className="price--offer">AED 999</h3>
            </div>
            <NavLink to="/register"><button className="button" >PRE ORDER NOW</button></NavLink>
          </div>
          {/* <div className="laptop">
              <img className="lapimg" ref={lapRef} srcSet={`${laptopcircle330} 330w, ${laptopcircle680} 680w, ${laptopcircle937} 937w`} src={laptopcircle937} alt="Dell 3310 laptop. The best choice for students" loading="lazy"/>
          </div> */}
          {/* <div className="laptop"> */}
              {/* <img className="laptop" ref={lapRef} srcSet={`${laptopsmall} 1100w, ${laptopcircle937} 1400w`} src={laptopcircle937} alt="Dell 3310 laptop. The best choice for students" loading="lazy"/> */}
              {/* <img className="circle" src="https://firebasestorage.googleapis.com/v0/b/dell-2ffd2.appspot.com/o/images%2Fcircle.svg?alt=media&token=3f5bc9f1-eaed-4649-951a-410eba976f9d" alt="Dell 3310 laptop. The best choice for students"/> */}
          {/* </div> */}
        </div>  
    );

}
const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(JumboLeft);