import React, {useRef, useEffect} from 'react';
import './JumboRight.scss'
// import CSSRulePlugin from "gsap/CSSRulePlugin";
import { gsap, TimelineLite, Power2 } from "gsap";
import student from "../../assets/student.webp";
import doukaniwhite from "../../assets/doukaniwhite.svg";
import laptopsmall from '../../assets/laptopsmall.png';
import laptoplarge from '../../assets/laptoplarge.png';
import laptopcircle330 from '../../assets/laptopcircle330.png'
import laptopcircle680 from '../../assets/laptopcircle680.png'
import laptopcircle937 from '../../assets/laptopcircle937.png'
import { Route, Switch } from 'react-router-dom';
import {connect} from 'react-redux';

const JumboRight = (props) =>{

  // gsap.registerPlugin(CSSRulePlugin);
  let containerRef = useRef(null);
  
  let heroRef = useRef(null);
  let laptopRef = useRef(null);
  // let imageReveal = CSSRulePlugin.getRule(".hero:after")

  const tl = new TimelineLite()
  useEffect(()=> {
    // tl.to(containerRef, 0, {
    //   visibility: "visible"
    // }).to(imageReveal, 1.4, {width: "0%", ease: Power2.easeInOut})
    tl.from(containerRef.current, 1.4,
      {autoAlpha: 0, ease: Power2.easeIn}
    )
    .from(laptopRef.current, 1.4, {autoAlpha: 0, delay: 1})
  },[])
  const lapImage =() => {
    return(
      <picture ref={el => laptopRef = el} className="laptop">
        <source srcSet={`${laptopsmall} 1100w, ${laptopcircle937} 1400w`} type="image/webp"/>
        <source srcSet={`${laptoplarge} 1100w, ${laptoplarge} 1400w`} type="image/jpeg"/>
        <img src={laptopsmall} alt="Dell 3310 laptop. The best choice for students" loading="lazy"/>
      </picture>
      // <img ref={el => laptopRef = el} className="laptop" srcSet={`${laptopsmall} 1100w, ${laptopcircle937} 1400w`} src={laptopcircle937} alt="Dell 3310 laptop. The best choice for students" loading="lazy"/>
    )
  }
  useEffect(() => {       
      // axios.get("https://firebasestorage.googleapis.com/v0/b/dell-2ffd2.appspot.com/o/images%2Fstudentwebp.webp?alt=media&token=c6116ee4-2044-4243-a184-1b072d7f9c0f")
      // .then((image) =>{
      //   setGirlImage(image)
      // })
      // .catch(function (error) {
      //   // handle error
      //   console.log(error);
      // })

      


    gsap.from(heroRef.current, {
        autoAlpha: 0,
        ease: 'none',
        delay: 5
    });

}, []);
  return(

    <div className="right" ref={el => (containerRef = el)}>
    <div className="hero" ref={el => heroRef = el}>
      <Switch>
        <Route path="/" exact component={lapImage} />
      </Switch>
      <img className="doukaniLogo" src={doukaniwhite} alt="Doukani official logo"/>
    </div>
  </div> 
  );
}
const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(JumboRight);