import React, {useRef, useState, useEffect } from 'react';
import {TweenMax, TimelineLite, Power3} from "gsap";
import {connect} from 'react-redux';
import './App.scss';
import Aux from "../hoc/Auxillary";
import withClass from "../hoc/WithClass";
import * as actionTypes from '../store/actions';
// import Items from '../components/Items/Items';
// import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import JumboLeft from '../components/JumboLeft/JumboLeft'
import JumboRight from '../components/JumboRight/JumboRight'
import MenuBuilder from './MenuBuilder/MenuBuilder';
import Offer from '../components/Offer/Offer';
import HowTo from '../components/HowTo/HowTo';
import Footer from '../components/Footer/Footer';
import Faq from '../components/Faq/Faq'
import Modal from '../components/UI/Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import doukani from '../assets/doukani.svg';



const App = (props) => {
  let appRef = useRef(null);
  let jumboRightRef = useRef(null)
  let offerRef = useRef(null)

  let tl = new TimelineLite()
  let [showModal, setShowModal] = useState(false)

  useEffect(()=> {
    TweenMax.to(appRef, 0, {css: {visibility: 'visible'}})
    // console.log(jumboRightRef)
    // tl.from(jumboRightRef, 1.2, {y:-100, ease: Power3.easeIn})
    tl.from(offerRef.current, 1.2, {y:-100, ease: Power3.easeIn})
    
  })
  const callToAction = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  const closeNotification = () => {
    setShowModal(false)
  }
  useEffect(()=> {
    console.log()
    setShowModal(props.status==='success' || props.status==='failed')
  },[props.status])
  return(
      <Aux>
        <div ref={el => appRef = el}>
        {/* <BrowserRouter> */}
          <Layout>
            <Modal show={showModal} modalClosed={closeNotification}>
              <div className="notification__message">
                <img className="logos_doukani" src={doukani} alt="Doukani Logo"/>

                <span className="message--light">{props.status? "Registration successful": "Registration Failed"}</span>
                <span className="message--dark">{props.status? "You will receive your voucher code through E-mail within 2-3 business days.":"Please check the details provided"}</span>
                <span className="message">{props.status?"For more informaiton, contact Doukani": "For more information, contact Doukani"}</span>
              </div>
            </Modal>
          {/* <div className="container"> */}
                {/* {registrations} */}
                {/* <Items/> */}


              {/* <button onClick={()=> this.registrationShowHandler()}>Show registration</button> */}
            <Switch>
              <Route path="/" exact component={JumboLeft} />
              <Route path="/register" exact component={MenuBuilder} />
            </Switch>
            <JumboRight forwardedRef={el => jumboRightRef = el}/>
            <Offer/>
            <HowTo callToAction={callToAction} offerRef={el => offerRef = el}/>
            <Faq />
            <Footer/>
            {/* </div> */}
          </Layout>
        {/* </BrowserRouter> */}
        </div>
      </Aux>
    );
};

const mapStateToProps = state => {
  return {
    status: state.reducer.status,
    error: state.error
  };
}
const mapDispatchToProps = dispatch => {
  return{
    onNotificationClosed: () => dispatch(actionTypes.setStatus())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default withClass(App,{});
