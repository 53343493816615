import React from 'react';
import'./Modal.scss'
import Backdrop from '../../UI/Backdrop/Backdrop'
import Aux from '../../../hoc/Auxillary'

const modal = (props) => (
        <Aux>
            <Backdrop
                show = {props.show} clicked={props.modalClosed}
                style={{
                    opacity: props.show? '1': '0'
                }}
            />
            <div 
                className="notification"
                style={{
                    transorm: props.show? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show? '1': '0',
                    display: props.show? 'inline-block': 'none'
                }}
            >
                {props.children}
            </div>
        </Aux>
);

export default modal;