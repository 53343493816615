import React from 'react';
import classes from "./NavigationItem.scss"
import { NavLink } from 'react-router-dom';
import {Link} from "react-scroll"

const navigationItem = (props) => { 
        const callToAction = () =>{
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
        return (
        (!props.isButton && props.isScrollLink)
        ?
        <li className="navLink"><Link 
            to={props.link} 
            activeClass="active" 
            spy={true} 
            smooth={true}
            offset={-120}
            duration={500}
            >{props.children}</Link></li>
        :
        <li className="navLink" ><NavLink onClick={callToAction} to={props.link} exact>{props.children}</NavLink></li>
    );

}

export default navigationItem;