import React, {useRef, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import {Link} from "react-scroll"
import './HowTo.scss'
import family520 from '../../assets/family520.webp'
import family763 from '../../assets/family763.webp'
import family960 from '../../assets/family960.webp'
import family520png from '../../assets/family520.png'
import family763png from '../../assets/family763.png'
import family960png from '../../assets/family960.png'
import register from '../../assets/register.svg'
import coupon from '../../assets/coupon.svg'
import laptop from '../../assets/laptop.svg'
import {connect} from 'react-redux';
import { gsap } from "gsap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { reduxFirebase } from 'react-redux-firebase';

const HowTo = React.forwardRef((props,ref) => {

    const instructionRef = useRef(null);
    const revealInstructionRefs = useRef([])
    revealInstructionRefs.current = []
    useEffect(() => {
      revealInstructionRefs.current.forEach((el, index)=> {
        gsap.fromTo(el, {autoAlpha: 0, y: '50px'}, {duration:1, autoAlpha:1, y:'0', ease: 'none', delay: (index*0.3)})
      })

  }, []);

    const addToRefs = (e) => {
      if(e && !revealInstructionRefs.current.includes(e)){
        revealInstructionRefs.current.push(e)
      }
    }
    const scrollToTop = () => {
      console.log("Hello")
    }
    return(
        <section id="section--how-to" ref={ref}>
            <h2 className="title--center">How To Redeem</h2>
            <p className="caption--center">Register and pre-order to avail the "Students Only" discount voucher</p>
            <div className="underline"></div>
            <div className="banner">
            <picture>
                <source 
                    srcSet={`${family960} 1400w,
                    ${family763} 1100w,
                    ${family520} 768w`}
                type="image/webp"/>
                <source 
                    srcSet={`${family960png} 1400w,
                    ${family763png} 1100w,
                    ${family520png} 768w`}
                type="image/webp"/>
                <img 
                    src={family960png}               
                    loading="lazy"
                    alt="Family enjoying content on dell laptop"/>
            </picture>
            </div>
            <div className="instructions">
              <div className="instruction" ref={addToRefs}>
                <div className="icon-holder">
                  <img src={register} alt="Register icon"/>
                  <h4 className="step-number">01</h4>
                </div>
                <div className="heading-holder">
                  <h4>Register yourself</h4>
                  <NavLink className="link" to='/register'>
                  <div className="action" onClick={props.callToAction}>
                    <span>Register</span>
                    <FontAwesomeIcon className="arrow__icon" icon={faArrowCircleRight} />
                  </div>
                  </NavLink>
                </div>                    
                <div className="underline"></div>
                <p>
                Register with your Student Id and Emirates ID details. This is to verify that the offer is availed only by students eligible for the offer. Click Register to get your voucher code.
                </p>
              </div>
              <div className="instruction" ref={addToRefs}>
              <div className="icon-holder">
                  <img src={coupon} alt="Coupon icon"/>
                  <h4 className="step-number">02</h4>
                </div>
                <div className="heading-holder">
                  <h4>Grab you voucher code</h4>
                </div>                    
                <div className="underline"></div>
                <p>
                On registering, you will receive your voucher code to the registered email id within 24 to 48 hours. Visit Doukani.com to buy the Dell 3310 Laptop for as low as AED 999.

                </p>
              </div>
              <div className="instruction" ref={addToRefs}>
              <div className="icon-holder">
                  <img src={laptop} alt="Laptop icon"/>
                  <h4 className="step-number">03</h4>
                </div>
                <div className="heading-holder">
                  <h4>Grab your laptop</h4>
                </div>                    
                <div className="underline"></div>
                <p>
                <a href="https://doukani.com/dell-latitude-/46bb3d37273a3e0dc4f3318d1b38aad0/" className="doukani__link">Click here</a> and add the Dell 3310 to your cart.At checkout enter the voucher code to avail the discount.
                </p>
              </div>
            </div>
        </section>
    );
})
const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(HowTo);