import * as actionTypes from './actions'
import {updateObject} from './utility';
import * as firestoreService from '../config/fsConfig';

const initialState = {
    status: '',
    loading: false
};
const deleteRegistration = (state, action) => {
    const updatedArray = state.registrations.filter(registration => registration.registrationId!==action.registrationId)
    return updateObject(state, {registrations: updatedArray});
}
const reducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch(action.type){
        case actionTypes.LOADING_STATUS:
            return updateObject(state, {loading: action.loading});
        case actionTypes.INIT_REGISTRATIONS:
            return updateObject(state, {registrations: action.registrations, currentRegistrationId: action.registrations[0].id});
        case actionTypes.ADD_REGISTRATION:
            return updateObject(state, {registrations: state.registrations.concat(action.registration)});
        case actionTypes.DELETE_REGISTRATION:
            return deleteRegistration(state, action);
        case actionTypes.REGISTRATION_STATUS_SUCCESS:
            return updateObject(state, {status: 'success'})
        case actionTypes.REGISTRATION_STATUS_FAILED:
            return updateObject(state, {status: 'failed'})
        case actionTypes.REGISTRATION_STATUS_RESET:
            return updateObject(state, {status: ''})
    }
    return state;
};

export default reducer;