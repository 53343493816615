import React, {Component} from 'react';
import './Footer.scss'
import dellLogo from '../../assets/delllogo.svg';
import doukani from '../../assets/doukani.svg';
import emircomLogo from '../../assets/emircom.svg';

import {connect} from 'react-redux';

class Footer extends Component{

    state = {
    };

    componentDidMount(){
    }
    render() {
        return(
            <footer>
              <div className="logos">
                  <img className="logos_dell" src={dellLogo} alt="Dell logo"/>
                  <img className="logos__emircom" src={emircomLogo} alt="Emircom logo"/>
                  <img className="logos_doukani" src={doukani} alt="Doukani Logo"/>
              </div>
              <div className="license">
                  <p className="footer__text">© 2020 Doukani LLC</p>
              </div>
              <div className="credits">
                  <p className="footer__text">Designed by: uwwwo.com</p>
              </div>
            </footer>
        );
    }
}
const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Footer);