import React, {Component} from 'react';
import './Offer.scss'
import LaptopImage from './LaptopImage/LaptopImage'
import latitude280 from '../../assets/latitude280.webp'
import latitude650 from '../../assets/latitude650.webp'
import {connect} from 'react-redux';

class Offer extends Component{

    state = {
    };

    componentDidMount(){
    }
    render() {
        return(
            <section id="section--offer">
                <h2 className="title--center">Our Offer Includes</h2>
                <p className="caption--center">Dell 3310 Laptop at prices you will not find elsewhere in the UAE.</p>
                <div className="underline"></div>

                <div className="offerGrid">
                    <div className="laptop">
                        {/* <img className="laptop--img" srcSet={`${latitude280} 280w, ${latitude650} 650w`} src={latitude650} alt="Dell 13 inch laptop with extended battery life" loading="lazy"/> */}
                        <LaptopImage/>
                        <div className="details">
                            <h3>Dell 3310</h3>
                            <p>
                            A 13-inch laptop made for students like you with best-in-class durability and extended battery life so you can learn without limits.
                            </p>

                            <div className="specs">
                                <div className="spec">
                                    <div className="dot"></div>
                                    <div className="text">Intel Celeron 4205u</div>
                                </div>
                                <div className="spec">
                                    <div className="dot"></div>
                                    <div className="text">4gb ram</div>
                                </div>
                                <div className="spec">
                                    <div className="dot"></div>
                                    <div className="text">13.3” lcd display</div>
                                </div>
                                <div className="spec">
                                    <div className="dot"></div>
                                    <div className="text">128gb ssd</div>
                                </div>
                            </div>
                            <div className="price">
                                <h3 className="price--real">AED 3050</h3>
                                <h3 className="price--offer">AED 999</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Offer);