import React from 'react'
import Aux from '../../hoc/Auxillary';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import classes from './Layout.scss'

const layout = (props) => (
    <Aux>
        <div className="container">
        <Toolbar />
            {props.children}
        </div>
    </Aux>
);

export default layout;