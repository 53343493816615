import React from 'react';
import classes from './NavigationItems.scss'
import NavigationItem from './NavigationItem/NavigationItem';


const navigationItems = () => (
    <ul>
            <NavigationItem isButton={true} link="/register">Pre Order Now</NavigationItem>
            <NavigationItem isScrollLink={true} link="section--how-to">How it works</NavigationItem>
            <NavigationItem isScrollLink={true} link="section--offer">Offer</NavigationItem>
            <NavigationItem link="/" isScrollLink={false}>Home</NavigationItem>
    </ul>
);
export default navigationItems;