import React, {Component} from 'react';

import Aux from '../../hoc/Auxillary';
import NewCategory from '../../components/Registrations/NewRegistration/NewRegistration';
// import NewItem from '../..//components/Items/NewItem/NewItem';

class MenuBuilder extends Component{
    render () {
        return (
            <Aux>
                <NewCategory/>
                {/* <NewItem/> */}
                <div>Menu Builder Controls</div>
            </Aux>
        );
    }
}

export default MenuBuilder;