import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

var firebaseConfig = {
  apiKey: "AIzaSyCUMECgtFsdyV3Xy5QRQlc6fvdTIhBp92c",
  authDomain: "dell-4bb85.firebaseapp.com",
  databaseURL: "https://dell-4bb85.firebaseio.com",
  projectId: "dell-4bb85",
  storageBucket: "dell-4bb85.appspot.com",
  messagingSenderId: "741410443850",
  appId: "1:741410443850:web:a1f13f842a104106d40cb3",
  measurementId: "G-L13BRR6YQY"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
//   firebase.analytics();
  const db = firebase.firestore();
  const storage = firebase.storage();
  const analytics = firebase.analytics();

export default firebase;

export const getRegistrationList = async () => {
    const snapshot = await db.collection('registration').get()
    return snapshot.docs.map(doc => {return {id: doc.id, ...doc.data()}});
};

// export const getItemsInCategory = async (categoryId) => {
//   console.log(`firebase category id is ${categoryId}`)
//   const snapshot = await db.collection('Item').where('category', '==', categoryId).get()
//   return snapshot.docs.map(doc => {console.log("called");return {id: doc.id, ...doc.data()}});
// }

export const createRegistration = (registration, id) => {
    analytics.logEvent('registration', {attempt: 1})
    return db.collection('Registration').doc(id)
        .set({...registration, registeredOn: firebase.firestore.FieldValue.serverTimestamp()});
}
// export const addItemToCategory = (categoryId, item) => {
//   return db.collection('Item')
//       .add(item);
// }

export const logRegistrationStart = () => {
  analytics.logEvent('registration_start', {attempt: 1});
}

export const getImageUrl = (filename, folder) => {
  return storage.ref(`/${folder}/${filename}`).getDownloadURL()
}

export const uploadRegistrationImage = (file, folder) => {
  return storage.ref(`/${folder}/${file.name}`).put(file)
}




