import React from 'react';
import classes from './LaptopImage.scss';
import latitude280 from '../../../assets/latitude280.webp'
import latitude650 from '../../../assets/latitude650.webp'
import latitude280png from '../../../assets/latitude280.png'
import latitude650png from '../../../assets/latitude650.png'
import {ReactComponent as YouTube} from '../../../assets/youtubesmall.svg'


const LaptopImage = () => (
    <React.Fragment>
        <div className="laptop--holder">
            {/* <img className="laptop--img" srcSet={`${latitude280} 280w, ${latitude650} 650w`} srcSet={`${latitude280png} 280w, ${latitude650png} 650w`} src={latitude650} alt="Dell 13 inch laptop with extended battery life" loading="lazy"/> */}
            <picture className="laptop--img">
                <source srcSet={`${latitude280} 280w, ${latitude650} 650w`} type="image/webp"/>
                <source srcSet={`${latitude280png} 280w, ${latitude650png} 650w`} type="image/png"/>
                <img src={latitude650png} alt="Dell 13 inch laptop with extended battery life" loading="lazy"/>
            </picture>
            {/* <YouTube /> */}
            <svg className="youtube--img" xmlns="http://www.w3.org/2000/svg" width="42.761" height="30.544" viewBox="0 0 42.761 30.544">
                <g id="Group_2071" data-name="Group 2071" transform="translate(0 0)">
                    <rect id="Rectangle_997" data-name="Rectangle 997" width="25.575" height="23.501" transform="translate(9.308 3.641)" fill="#f7f7f7"/>
                    <path id="Icon_metro-youtube-play" data-name="Icon metro-youtube-play" d="M33.115,21.882a1.373,1.373,0,0,0-.716-1.289L20.181,12.958a1.439,1.439,0,0,0-1.551-.048,1.422,1.422,0,0,0-.787,1.336V29.518a1.422,1.422,0,0,0,.787,1.336,1.65,1.65,0,0,0,.74.191,1.359,1.359,0,0,0,.811-.239L32.4,23.171a1.373,1.373,0,0,0,.716-1.289Zm12.218,0q0,2.291-.024,3.579t-.2,3.257a26.4,26.4,0,0,1-.537,3.52,5.582,5.582,0,0,1-1.647,2.935,5.032,5.032,0,0,1-2.959,1.384,156.443,156.443,0,0,1-16.012.6,156.442,156.442,0,0,1-16.012-.6,5.086,5.086,0,0,1-2.971-1.384A5.547,5.547,0,0,1,3.31,32.239a29.538,29.538,0,0,1-.513-3.52q-.179-1.969-.2-3.257t-.024-3.579q0-2.291.024-3.579t.2-3.257a26.4,26.4,0,0,1,.537-3.52A5.582,5.582,0,0,1,4.981,8.591,5.032,5.032,0,0,1,7.94,7.207a156.444,156.444,0,0,1,16.012-.6,156.441,156.441,0,0,1,16.012.6,5.086,5.086,0,0,1,2.971,1.384,5.548,5.548,0,0,1,1.658,2.935,29.537,29.537,0,0,1,.513,3.52q.179,1.969.2,3.257T45.332,21.882Z" transform="translate(-2.57 -6.61)" fill="#f33"/>
                </g>
            </svg>

        </div>
    </React.Fragment>
);
export default LaptopImage;