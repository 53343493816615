import React from 'react';
import './Faq.scss'
import faq550 from '../../assets/faq550.png';
import faq466 from '../../assets/faq466.png';
import faq from '../../assets/faq.jpg';

import {connect} from 'react-redux';
import Question from './Question/Question';

const questions = [
    {
        question: "1. Can anyone avail the super saver offer?",
        answer: "No. This offer is valid only for students in the UAE. You have to register using your students id and emirates id using this <a href='/register'>Registration form</a> to receive the voucher code. You can apply the voucher code on checkout to avail the discount."
    },
    {
        question: "2. I received the voucher code via email. Now what?",
        answer: "Head to <a href='electronics.doukani.com'>electronics.doukani.com</a> and add the Laptop to your cart. On checkout enter the voucher code. The total bill will be adjusted to the discounted price."
    },
    {
        question: "3. I registered but did not receive the voucher code.",
        answer: "Please make sure that your student id and emirates id are valid. Please check your spam folder. For any technical support, write to marketing@doukani.com or whatsapp 050 1299053."
    },
    {
        question: "4. Can I buy more than one laptop at this price?",
        answer: "Yes, you can if your household has more than one student. You can register each student separately. One laptop bundle per student id."
    },
    {
        question: "5. Can I re-use the voucher code?",
        answer: "The voucher code is valid only for a single purchase and for the prescribed products."
    },
    {
        question: "6. How long should I wait to receive the voucher code?",
        answer: "Upon successful validation of your request, you will receive the voucher code in your registered email id within 24 to 48 hours. The voucher code is valid for 7 days from the date of issue."
    },
    {
        question: "7.  What is the validity of the voucher code?",
        answer: "You must complete the transaction within 7 days of the issue of the voucher code. The voucher code is valid for 7 days from the date of issue."
    },
    {
        question: "8. Can the Laptop be returned/ replaced?",
        answer: "Standard return policies apply. Refer to Return Policy on Doukani.com"
    }
]

const Faq = () =>{

    return(
        <section className="faq__section">
            <h2 className="title--center">Frequently Asked Questions</h2>
            <p className="caption--center">Doukani fullfills your order</p>
            <div className="underline"></div>
            <div className="banner"></div>
            <div className="faq">
                {/* <div className="faq__image" style={{backgroundImage: `url(${faq550})`}}> */}
                    <img className="faq__image" src={faq} alt="Person clarifying questions to another person who uses a laptop"/>
                {/* </div> */}
            {/* <picture  className="faq__image">
                <source 
                    srcSet={`${faq550} 1400w`}
                    type="image/webp"/>
                <source 
                    srcSet={`${faq}`}
                type="image/jpg"/>
                <img 
                    src={faq}               
                    loading="lazy"
                    alt="Family enjoying content on dell laptop"/>
            </picture> */}
                <div className="faq__content">
                {
                    questions.map(({question, answer}, index) => {
                        return (
                            <Question key={index} question={question} answer={answer}/>
                        );
                    })
                }
            </div>
            </div>

        </section>
    );
}

const mapStateToProps = state => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return{
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Faq);